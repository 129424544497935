import React from 'react'
// import Container from '../ui/Container'
import Heading from '../ui/Heading'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import divider from '../../../../Assets/images/yellow-divider.png';

import Holiday1 from "../../../../Assets/images/holiday-1.png";
import Holiday2 from "../../../../Assets/images/holiday-2.jpeg";
import Holiday3 from "../../../../Assets/images/holiday-3.jpeg";
import Holiday4 from "../../../../Assets/images/holiday-4.jpeg";
import Holiday5 from "../../../../Assets/images/holiday-5.jpg";

const ExploreHolidayCollection = () => {
    return (
        <section className='explore-collection explore-holiday-collection section-space'>
            <Container fluid className='showcase-container'>
                <Heading>
                    <h2>Top Trending Collection</h2>
                    <Image src={divider} alt='' />
                </Heading>

                <Row>
                    <Col md={12} xs={12}>
                        <div className='item text-center'>
                            <Link to='/holiday-jewelry' className='img-box'>
                            <Image src={Holiday1} alt='lab-grown engagement rings' /></Link>
                            <Link to='/holiday-jewelry'>Shop Now</Link>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='item text-center'>
                            <Link to='/holiday-jewelry' className='img-box'><Image src={Holiday2} alt='lab-grown diamond rings' /></Link>
                            <Link to='/holiday-jewelry'>Bracelet</Link>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='item text-center'>
                            <Link to='/holiday-jewelry' className='img-box'><Image src={Holiday3} alt='lab diamond tennis bracelet' /></Link>
                            <Link to='/holiday-jewelry'>Earrings</Link>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='item text-center'>
                            <Link to='/holiday-jewelry' className='img-box'><Image src={Holiday4} alt='lab grown diamond necklace' /></Link>
                            <Link to='/holiday-jewelry'>Pendant</Link>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className='item text-center'>
                            <Link to='/holiday-jewelry' className='img-box'><Image src={Holiday5} alt='best lab grown diamond earrings' /></Link>
                            <Link to='/holiday-jewelry'>Engagement Ring </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ExploreHolidayCollection